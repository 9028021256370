body,
html {
    height: 100%;
    margin: 0;
}

main {
    background-color: #FBF8E3;
    min-height: calc(100vh - 10vh);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.photo-grid-display {
    position: relative;
    color: white;
    margin-top: 0;
    top: 0;
    height: 80vh;
}

.photo-grid-display .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 3% 4%;
    z-index: 2;
}

.slideshow {
    position: relative;
    height: 80vh;
    z-index: 1;
}

.slideshow img {
    position: absolute;
    width: 100%;
    height: 80vh;
    object-fit: cover;
    top: 0;
    left: 0;
}

#home-blurb {
    padding: 3% 4% 3% 4%;
    z-index: 2;
    width: 80%;
    margin: auto;
}

#home-blurb h1, 
#home-blurb p {
    text-align: start;
}

#event-blurb {
    padding: 3% 4% 3% 4%;
    z-index: 2;
}

#event-blurb h2, 
#event-blurb p {
    text-align: start;
}

.scroll-down-btn {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: none;
    border: none;
    color: white;
    cursor: pointer;
}


#events {
    background-color: #f9f8f1;
    border: solid #e7c942 1px;
    border-radius: 20px;
    padding: 2%;
    width: 80%;
    justify-content: center;
    margin: auto;
    margin-bottom: 3%;
}

#event-gallery {
    padding: 2%;
    width: 80%;
    justify-content: center;
    margin: auto;
    margin-bottom: 3%;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2%;
}

.event-list-container {
    padding: 3.5%;
    height: calc(100vh - 40px);
  }
  
  .event-list {
    padding: 0 2% 0 2% ;
    overflow-y: auto;
    height: 100%;
  }

  .event-card {
    position: relative;
    display: block;
    width: 100%;
    margin: auto;
    margin-bottom: 1rem;
    color: white;
    text-align: start;
    padding: 3%;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .event-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.712);
    border-radius: 4px;
    z-index: 1;
    transition: background-color 0.5s;
  }

  .event-card:hover::before {
    background-color: rgba(0, 0, 0, 0.867);
  }
  
  .event-card-content {
    position: relative;
    z-index: 2;
    color: white;
    padding: 1%;
  }

.photo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4% 2% 2% 2%;
    margin: auto;
}

.photo-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 2%;
    width: 100%;
    margin: auto;
    justify-content: center;
    align-items: center;
}

.photo {
    background-color: transparent;
    padding: 0.5%;
    text-align: center;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: none;
}

.photo img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.photo:hover {
    transform: scale(1.1);
    transition: transform 0.5s ease-in-out;
}

#noImg {
    width: 100%;
    text-align: center;
    color: #666;
}

.footer {
    width: 100%;
    height: 10vh;
    position: relative;
    bottom: 0;
    z-index: 1000;
}

/* photo page */

#photo {
    background: #000000;
    margin: 0;
    height: 100vh;
    width: 100vw;
}

.photo-bg {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    padding: 0 1% 0 1%;
}

.btnGroup {
    width: 100%;
    padding: 1%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rightBtns {
    display: flex;
    justify-content: center;
    align-items: center;
}

.rightBtn, .leftBtn, #warnBtn {
    background: transparent;
    border: none;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0 10px;
}

button h3 {
    margin-left: 4%;
    font-size: 20px;
    align-items: center;
    justify-content: center;
}

.selectedPhoto {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    width: 100vw;
}

.selectedPhoto img {
    max-width: 80%;
    max-height: 80vh;
    object-fit: contain;
}

.photo-number {
    text-align: center;
    color: white;
    margin-top: 10px;
}

.report-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.report-container {
    position: relative;
    width: 50%;
    height: auto;
    margin: auto;
    background: rgb(255, 255, 255);
    padding: 2%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.close-button {
    cursor: pointer;
}

.report-form {
    background-color: #f8f9fa;
    padding: 0.5%;
    border-radius: 10px;
    display: flex;
    justify-content: center;

}

.custom-radio-button {
    margin: 2%;
    width: 75%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-color: #007bff;
    padding: 10px;
    border-radius: 10px;
    transition: background-color 0.3s, color 0.3s;
}

.custom-radio-button:hover {
    color: #ffffff;
    border-color: #0056b3;
}

/* form css */

.form {
    background-color: #fbe47d80;
    border: solid #FBE37D 1px;
    border-radius: 20px;
    width: 50%;
    display: block;
    justify-content: center;
    align-items: center;
    padding: 3%;
    margin: auto;
    margin-top: 1%;
    margin-bottom: 2%;
}

#form-header {
    padding-top: 1%;
    font-size: 30px;
    text-align: start;
    margin-left: 10%;
}

.form-control {
    padding: .5rem .75rem;
    border-radius: .25rem;
    border: 1px solid #ced4da;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
}

.form-label {
    color: rgb(54, 54, 54);
    text-align: left;
}

.btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    padding: .5rem 1rem;
    border-radius: .25rem;
}

.btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + .75rem);
    background-image: none;
}

.form button[type="submit"] {
    display: block; /* Make button a block element */
    margin-top: 1rem; /* Add some margin on the top */
    margin-left: auto; /* Align to the right */
    margin-right: 0; /* Align to the right */
}

